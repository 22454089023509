import { useMutation } from "react-query";

const logout = async (): Promise<string | null> => {
  // const { data } = await axios.post("/auth/logout");
  // return data;
  return null;
};

export function useLogout() {
  const { isLoading, mutateAsync } = useMutation(logout);

  return { isLoggingOut: isLoading, logout: mutateAsync };
}
