import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ConfirmProvider } from "material-ui-confirm";
import AppRoutes from "./AppRoutes";
import AuthProvider from "./contexts/AuthProvider";
import Loader from "./components/Loader";
import QueryWrapper from "./components/QueryWrapper";
import SettingsProvider from "./contexts/SettingsProvider";
import SnackbarProvider from "./contexts/SnackbarProvider";
import axios, { AxiosResponse } from "axios";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,      
      retry: 0,
      suspense: false,
    },
  },
});

const redirectToLoginPage = () => {
  localStorage.setItem("expiration", "");
  localStorage.setItem("authkey", "");
  window.location.href = "/login";
};

// Set axios defaults
axios.defaults.baseURL = "https://app.netcondominium.com";
axios.defaults.headers.post["Content-Type"] = "application/json";

//Add a request interceptor
axios.interceptors.request.use(
  (config) => {
  const token = localStorage.getItem("authkey") ?? "";
    if (token) {
      const expiration = localStorage.getItem("expiration");
      if (expiration && new Date(JSON.parse(expiration)) < new Date())
        redirectToLoginPage();
      else
        config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
    }
  return config;
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error: any) => {
    if (error.response.status === 401)
      redirectToLoginPage();
    return Promise.reject(error);
  }
);

function App() {
  return (
    <React.Suspense fallback={<Loader />}>
      <QueryClientProvider client={queryClient}>
        <SettingsProvider>
          <ConfirmProvider
            defaultOptions={{
              confirmationButtonProps: { autoFocus: true },
            }}>
            <QueryWrapper>
              <SnackbarProvider>
                <AuthProvider>
                  <AppRoutes />
                </AuthProvider>
              </SnackbarProvider>
            </QueryWrapper>
          </ConfirmProvider>
        </SettingsProvider>
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </React.Suspense>
  );
}

export default App;
