import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";

// Admin
const Admin = lazy(() => import("./pages/Admin"));
const Home = lazy(() => import("./pages/Home"));
const Profile = lazy(() => import("./pages/Profile"));

// Auth
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ForgotPasswordSubmit = lazy(() => import("./pages/ForgotPasswordSubmit"));
const Login = lazy(() => import("./pages/Login"));

// Core
const Forbidden = lazy(() => import("./pages/Forbidden"));
const NotFound = lazy(() => import("./pages/NotFound"));
const UnderConstructions = lazy(() => import("./pages/UnderConstructions"));

// Customers
const Contracts = lazy(() => import("./pages/Contracts"));

// Billings
const Extras = lazy(() => import("./pages/Extras"));

const AppRoutes = () => {
  return (
    <Routes basename={process.env.PUBLIC_URL}>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<Admin />}>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/contracts" element={<Contracts />} />
          <Route path="/extras" element={<Extras />} />
        </Route>
      </Route>
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="qforgot-password-submit" element={<ForgotPasswordSubmit />} />
      <Route path="/login" element={<Login />} />
      <Route path="/under-construction" element={<UnderConstructions />} />
      <Route path="/403" element={<Forbidden />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to={`${process.env.PUBLIC_URL}/404`} replace={true} />}
      />
    </Routes>
  );
};

export default AppRoutes;
