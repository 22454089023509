import axios from "axios";
import { useQuery } from "react-query";
import { UserInfo } from "../types/userInfo";

const fetchUserInfo = async (): Promise<UserInfo> => {
  const { data } = await axios.get("/api/auth/users/current/info");
  return data;
};

export function useUserInfo(key?: string | null) {
  return useQuery(["user-info"], () => fetchUserInfo(), {
    enabled: !!key, suspense: true
  });
}
