import React, { createContext, useContext } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useLogin } from "../hooks/useLogin";
import { useLogout } from "../hooks/useLogout";
import { useUserInfo } from "../hooks/useUserInfo";
import { UserInfo } from "../types/userInfo";
import { ApiResponse } from "../types/apiResponse";
import { useRefreshLogin } from "../hooks/useRefreshLogin";

interface AuthContextInterface {
  hasRole: (roles?: string[]) => {};
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  isRefreshing: boolean;
  login: (username: string, password: string) => Promise<ApiResponse | null>;
  logout: () => Promise<void>;
  refresh: () => Promise<string | null>;
  userInfo?: UserInfo;
  isExpired: boolean;
}

export const AuthContext = createContext({} as AuthContextInterface);

type AuthProviderProps = {
  children?: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authKey, setAuthKey] = useLocalStorage<string | null>("authkey", null);
  const [expiration, setExpiration] = useLocalStorage<string | null>("expiration", null);

  const { isLoggingIn, login } = useLogin();
  const { isLoggingOut, logout } = useLogout();
  const { isRefreshing, refresh } = useRefreshLogin();
  const { data: userInfo } = useUserInfo(authKey);

  const hasRole = (roles?: string[]) => {
    if (!roles || roles.length === 0) {
      return true;
    }
    if (!useUserInfo) {
      return false;
    }
    return true; //roles.includes(userInfo.role);
  };

  const handleLogin = async (username: string, password: string) => {
    return login({ username, password })
      .then((result) => {
        if (result.success) {
          setAuthKey(result.token ?? null);
          setExpiration(result.expiration ? new Date(result.expiration).toISOString() : null);
        }
        return result;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  const handleRefresh = async () => {
    return refresh()
      .then((result) => {
        if (result.success) {
          setAuthKey(result.token ?? null);
          setExpiration(result.expiration ? new Date(result.expiration).toISOString() : null);
        }
        return result.token ?? null;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  const handleLogout = async () => {
    return logout()
      .then((data) => {
        setAuthKey(null);
        setExpiration(null);
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <AuthContext.Provider
      value={{
        hasRole,
        isLoggingIn,
        isLoggingOut,
        isRefreshing,
        login: handleLogin,
        logout: handleLogout,
        refresh: handleRefresh,
        userInfo,
        isExpired: expiration && expiration.length > 0 ? new Date(expiration) < new Date() : true,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;
