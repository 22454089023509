import axios from "axios";
import { useMutation } from "react-query";
import { ApiResponseAuth } from "../types/apiResponseAuth";

const login = async ({
  username,
  password,
}: {
  username: string;
  password: string;
  }): Promise<ApiResponseAuth> => {
  const { data } = await axios.post<ApiResponseAuth>("/api/auth/token",
    new URLSearchParams({
      grant_type: "client_credentials",
      client_id: username,
      client_secret: password,
    })
  );

  return data;
};

export function useLogin() {
  const { isLoading, mutateAsync } = useMutation(login);
  return { isLoggingIn: isLoading, login: mutateAsync };
}
