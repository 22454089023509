import { Navigate, Outlet } from "react-router";
import { useAuth } from "../contexts/AuthProvider";

type PrivateRouteProps = {
  roles?: string[];
};

const PrivateRoute = ({
  roles
} : PrivateRouteProps) => {
  const { hasRole, userInfo, isExpired } = useAuth();

  if (!userInfo || isExpired) {
    return <Navigate to={`${process.env.PUBLIC_URL}/login`} replace={true} />;
  }

  if (!hasRole(roles)) {
    return <Navigate to={`${process.env.PUBLIC_URL}/403`} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
