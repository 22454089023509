import axios from "axios";
import { useMutation } from "react-query";
import { ApiResponseAuth } from "../types/apiResponseAuth";

const refreshLogin = async (): Promise<ApiResponseAuth> => {
  const { data } = await axios.post<ApiResponseAuth>("/api/auth/token/refresh");
  return data;
};

export function useRefreshLogin() {
  const { isLoading, mutateAsync } = useMutation(refreshLogin);
  return { isRefreshing: isLoading, refresh: mutateAsync };
}
